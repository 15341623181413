<template>
  <v-container fluid class="pt-0">
    <v-row>
      <v-col cols="12" v-if="pricing" style="overflow: hidden">
				<v-subheader>Nuevo pricing</v-subheader>
				<v-divider class="mb-2"></v-divider>
        <NuevoPricing
          ref="newPricing"
          @cancel="revision = null;pricing = null"
          @reload="reload"
          :key="keyHelper"
          :value="pricing"
          :espacios="espacios"
          :cartas="cartas"
          :tipo="tipo"
          :tipoPpto="tipoPpto"
          :modificaciones="modificaciones"
          :familias="familias"
          :anteriores="anteriores"
        />
      </v-col>
      <v-col cols="12" v-if="revision">
				<v-subheader>Revisión anterior</v-subheader>
				<v-divider class="mb-2"></v-divider>
        <RevisionPricing
          :value="revision"
          :espacios="espacios"
          :cartas="cartas"
          :tipo="tipo"
          :tipoPpto="tipoPpto"
          :modificaciones="modificaciones"
        />
      </v-col>
      <v-col cols="12">
        <v-subheader>Listado pricings</v-subheader>
        <v-divider class="mb-2"></v-divider>
        <v-data-table
          fixed-header
          :items="pricings.filter(a => !loading)"
          :headers="pricingsHeader"
          class="fixed-checkbox"
          :single-select="false"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [5, -1],
          }"
          checkbox-color="secondary">
          <template v-slot:body.prepend="{ headers }">
            <table-filters
              :headers="headers"
              :items="pricings"
              v-model="inlineFilters"
            ></table-filters>
          </template>
          <template v-slot:item.n="{ item, index }">
            {{ index+1 }}
            <!-- <td style="white-space: nowrap !important;padding-left: 22px !important;">{{ index+1 }}</td> -->
          </template>
          <template v-slot:item.pDef="{ item, index }">
            {{ parseFloat(item.pDef).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
          </template>
          <template v-slot:item.fechaEv="{ item, index }">
            {{ item.fechaEv ? new Date(item.fechaEv).toLocaleDateString('esp', { day : 'numeric', month : 'short', year : 'numeric' }).toLocaleUpperCase() : 'POR DEFINIR' }}
          </template>
          <template v-slot:item.fecha="{ item, index }">
            {{ item.fecha ? new Date(item.fecha).toLocaleDateString('esp', { day : 'numeric', month : 'short', year : 'numeric' }).toLocaleUpperCase() : 'MISSING No.'}}
          </template>
          <template v-slot:item.pptoShow="{ item, index }">
            {{ (tipoPpto.find(p => p.val == item.tipoPpto)?.title || 'POR DEFINIR') }}
          </template>
          <template v-slot:item.revision="{ item, index }">
            <td style="font-size: xx-large" class="error--text text-center font-weight-bold">
              {{ item.revision+1 }}
            </td>
          </template>
          <template v-slot:item.cartaShow="{ item, index }">
            {{ item.cartaShow }}
          </template>
          <template v-slot:item.acciones="{ item }">
            <div class="d-flex">
              <v-btn
                @click="exportarPdf(item)"
                small
                rounded
                color="error">
                <v-icon left>mdi-file-pdf-box</v-icon>
                PDF
              </v-btn>
              <v-btn
                @click="exportarExcel(item)"
                small
                rounded
                color="success">
                <v-icon left>mdi-file-excel-box</v-icon>
                EXCEL
              </v-btn>
              <v-btn
                @click="getRev(item)"
                small
                rounded
                color="secondary">
                <v-icon left>mdi-pencil</v-icon>
                Editar
              </v-btn>
              <v-btn
                @click="deletePricing(item)"
                small
                class="ml-1"
                rounded
                color="error">
                <v-icon left>mdi-delete</v-icon>
                Eliminar
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div
    style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000"
    bottom
    class="d-flex mt-2 ms-auto">
      <v-btn
        color="primary"
        fab
        @click="activate"
        large
        class="me-4"
      >
        <v-icon>mdi-plus</v-icon>
        <!-- Añadir evento -->
      </v-btn>
    </div>
		<ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { parseDate, perColumnFilter } from "@/utils/index.js";
import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";

export default {
  props: {
    loading: Boolean,
    espacios: Array,
    cartas: Array,
    pricings: Array,
    modificaciones: Array,
    familias: Array,
    tipo: Number,
    tipoPpto: Array
  },
  components: {
    NuevoPricing: () => import("./NuevoPricing.vue"),
    RevisionPricing: () => import("./RevisionPricing.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      active: false,
      pricing: null,
      revision: null,
      inlineFilters: {},
      anteriores:[],
      keyHelper: 0,
      pricingsHeader: [
        { text: "Nº", value: "n",                                     cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Clientes", value: "clientes",                        cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Espacio", value: "espacioShow", dataType: "select",  cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Fecha Evento", value: "fechaEv", cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Nº Presupuesto", value: "revision", dataType: "select", cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Personas", value: "personas",                        cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Tipo Ppto", value: "pptoShow",                       cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Fecha Ppto", value: "fecha", cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Carta", value: "cartaShow", dataType: "select",      cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Precio", value: "pDef",                              cellClass: "pa-1 text-no-wrap font-weight-bold text-right" },
        { text: "% Dif", value: "pntDif",                             cellClass: "pa-1 text-no-wrap font-weight-bold text-right" },
        { text: "Notas", value: "notas",                              cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Acciones", value: "acciones", sortable: false, filterable: false },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  watch: {
    pricing() {
      this.keyHelper++;
    },
  },
  methods: {
    activate(){
      // $set($refs.newPricing,'active',!$refs.newPricing.active)
      this.$set(this, 'pricing', {})
    },
    exportarPdf(item){
      this.$set(this,'anteriores',this.pricings.filter(p => p.idPricing == item.idPricing && p.revision != item.revision))
      var v = window.open('', '_blank','')
      v.document.write('<head><title>' + `${(item.fechaEv ? new Date(new Date(item.fechaEv).getTime() + (new Date().getTimezoneOffset()*-1)*60000).toISOString().split('T')[0] : 'POR DEFINIR') + " " + item.clientes + " " + (this.espacios.find(e => e.idEspacio == item.idEspacio)?.siglas || 'PTE') + " " + (this.tipoPpto.find(e => e.val == item.tipoPpto)?.siglas || '?') + " (" + (item.fechaEv ? new Date(new Date(item.fechaEv).getTime() + (new Date().getTimezoneOffset()*-1)*60000).toISOString().split('T')[0] : 'Missing No.')})` + '</title><style>ul {font-size: 14px !important};</style></head>');
      var l = v.document.createElement('link');
      l.rel = 'stylesheet';
      l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
      //titulo
      var wrapper = v.document.createElement('div')
      wrapper.style = "display:block;-webkit-print-color-adjust:exact !important;print-color-adjust:exact !important;"
      var div = v.document.createElement('div')
      div.style = "display: flex; flex-wrap: wrap; width: 100%;align-items: flex-start;"

      var tabla = v.document.createElement('table')
      tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:100%"
      tabla.innerHTML = '<colgroup><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"></colgroup>'
      var thead = v.document.createElement('thead')
      thead.innerHTML = '<tr><th colspan="20" style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRESUPUESTO</th></tr>'
      tabla.appendChild(thead)
      var tbody = v.document.createElement('tbody')
      tbody.innerHTML = "<tr><td colspan='10' style='border:1px solid black;color: #2196f3'><b style='color:black'>FECHA PPTO: </b>" + (item.fecha ? new Date(item.fecha).toLocaleDateString('esp', { day: 'numeric', month : 'short', year: 'numeric' }).toUpperCase() : 'POR DEFINIR') + "</td><td colspan='10' style='border:1px solid black;color: #2196f3'><b style='color:black'>TIPO PPTO: </b>" + (this.tipoPpto.find(p => p.val == item.tipoPpto)?.title || '') + "</td></tr>"
      tabla.appendChild(tbody)
      div.appendChild(tabla)

      var tabla = v.document.createElement('table')
      tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:100%"
      tabla.innerHTML = '<colgroup><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"></colgroup>'
      var thead = v.document.createElement('thead')
      thead.innerHTML = '<tr><th colspan="20" style="padding: 3px;background-color: lightgray ; border:1px solid gray">DATOS</th></tr>'
      tabla.appendChild(thead)
      var tbody = v.document.createElement('tbody')
      tbody.innerHTML = "<tr><td colspan='8' style='border:1px solid black;color: #2196f3'><b style='color:black'>CLIENTE: </b>" + (item.clientes || '') + "</td><td colspan='6' style='border:1px solid black;color: #2196f3'><b style='color:black'>ESPACIO: </b>" + (this.espacios.find(e => e.idEspacio == item.idEspacio)?.nombreEspacio || 'POR DEFINIR') + "</td><td colspan='6' style='border:1px solid black;color: #2196f3'><b style='color:black'>FECHA: </b>" + (item.fechaEv ? new Date(item.fechaEv).toLocaleDateString('esp', { day: 'numeric', month : 'short', year: 'numeric' }).toUpperCase() : 'POR DEFINIR') + "</td></tr>"
      tbody.innerHTML += "<tr><td colspan='10' style='border:1px solid black;color: #2196f3'><b style='color:black'>PERSONAS: </b>" + (item.personas || '') + "</td><td colspan='10' style='border:1px solid black;color: #2196f3'><b style='color:black'>CARTA BASE: </b>" + (item.idCarta ? this.cartas.find(c => c.idCarta == item.idCarta)?.nombre + ' ' + this.espacios.find(e => e.idEspacio == this.cartas.find(c => c.idCarta == item.idCarta)?.idEspacio)?.nombreEspacio + this.cartas.find(c => c.idCarta == item.idCarta)?.year : 'POR DEFINIR') + "</td></tr>"
      if (item.notas) tbody.innerHTML += "<tr><td colspan='20' style='border:1px solid black; background-color: #d4ffff;color: #2196f3'><b style='color:black'>NOTAS: </b>" + item.notas + "</td></tr>"
      tabla.appendChild(tbody)

      var thead = v.document.createElement('thead')
      thead.innerHTML = '<tr><th colspan="20" style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRICING</th></tr>'
      var tbody = v.document.createElement('tbody')
      tbody.innerHTML += "<tr><td colspan='10' style='border:1px solid black; color: #2196f3;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b style='color: black'>Precio cliente:</b> " + parseFloat(item.pDef ||this.prTotal(item)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</div></td><td colspan='10' style='border:1px solid black; color: #2196f3;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b style='color: black'>Precio pricing:</b> " + parseFloat(this.prTotal(item)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</div></td></tr>"
      tbody.innerHTML += "<tr><td colspan='8' style='border:1px solid black; color: #2196f3;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b style='color: black'>Total descuento:</b> " + (parseFloat(item.pDef ? (item.pDef-this.prTotal(item)) : 0) * (item.personas || 0)).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</div></td><td colspan='6' style='border:1px solid black; color: #2196f3;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b style='color: black'>Descuento (€):</b>" + parseFloat(item.pDef ? (item.pDef-this.prTotal(item)) : 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</b></td><td style='border:1px solid black; color: #2196f3' colspan='6'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b style='color: black'>Descuento (%):</b>" + parseFloat((item.pntDif || 0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "%</b></td></tr>"
      if (item.notasResult) tbody.innerHTML += "<tr><td colspan='20' style='border:1px solid black; color: #2196f3; background-color: #d4ffff'><b style='color: black'>NOTAS: </b>" + item.notasResult + "</td></tr>"
      tabla.appendChild(thead)
      tabla.appendChild(tbody)
      div.appendChild(tabla)

      var aux = item.modificaciones.filter(mod => [1,2,3].includes(mod.tipoModificacion))
      item.modificacionesCustom.map(m => {m.editable = true; return m}).forEach(element => {
        aux.splice(element.orden,0,element)
      });
      if (aux.length > 0){
        var tabla = v.document.createElement('table')
        tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:calc(100%/3)"
        var thead = v.document.createElement('thead')
        thead.innerHTML = '<tr><th colspan="3" style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRICING</th></tr>'
        thead.innerHTML += '<tr><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">ITEM</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">UDS</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRECIO</th></tr>'
        tabla.appendChild(thead)
        var tbody = v.document.createElement('tbody')
        if (item.idCarta) tbody.innerHTML += "<tr style='border: 1px solid black'><td>" + ((this.cartas.find(c => c.idCarta == item.idCarta)?.nombre || '') + " " + (this.espacios.find(e => e.idEspacio == this.cartas.find(c => c.idCarta == item.idCarta)?.idEspacio)?.nombreEspacio || '') + " " + (this.cartas.find(c => c.idCarta == item.idCarta)?.year || '')) + "</td><td style='text-align:center'>1</td><td style='text-align:right'>" + parseFloat(this.cartas.find(c => c.idCarta == item.idCarta)?.precio || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "€</td></tr>"
        aux.forEach(mod => {
          if (mod.editable) tbody.innerHTML += "<tr style='border: 1px solid black'><td><b style='color: red'>" + ["ADD ", "QUIT ", "SUST "][mod.tipoModificacion-1] + "</b>" + (mod.nombre || '') + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + (parseFloat(mod.precio || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + "€</td></tr>"
          else tbody.innerHTML += "<tr style='border: 1px solid black'><td><b style='color: red'>" + ["ADD ", "QUIT ", "SUST "][mod.tipoModificacion-1] + "</b>" + (this.modificaciones.find(m => m.idItem == mod.idItem)?.nombre) + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + ([parseFloat(this.modificaciones.find(m => m.idItem == mod.idItem)?.add || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, "."), parseFloat(this.modificaciones.find(m => m.idItem == mod.idItem)?.remove || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, "."), parseFloat(this.modificaciones.find(m => m.idItem == mod.idItem)?.replace || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")][mod.tipoModificacion-1]) + "€</td></tr>"
        })
        if (item.notasModificaciones) tbody.innerHTML += "<tr><td colspan='3' style='border:1px solid black; color: #2196f3; background-color: #d4ffff'><b style='color: black'>NOTAS: </b>" + item.notasModificaciones + "</td></tr>"
        tabla.appendChild(tbody)
        div.appendChild(tabla)
      }

      var aux = item.catering.filter(mod => [4,5,6].includes(mod.tipoModificacion))
      if (aux.length > 0){
        var tabla = v.document.createElement('table')
        tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:calc(100%/3)"
        var thead = v.document.createElement('thead')
        thead.innerHTML = '<tr><th colspan="3" style="padding: 3px;background-color: lightgray ; border:1px solid gray">CATERING</th></tr>'
        thead.innerHTML += '<tr><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">ITEM</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">UDS</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRECIO</th></tr>'
        tabla.appendChild(thead)
        var tbody = v.document.createElement('tbody')
        aux.forEach(mod => {
          tbody.innerHTML += "<tr style='border: 1px solid black'><td><b style='color: red'>" + ["ADD ", "QUIT ", "SUST "][mod.tipoModificacion-4] + "</b>" + (mod.nombre) + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + parseFloat(mod.precio || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "€</td></tr>"
        })
        tbody.innerHTML += "<tr style='border:1px solid black;'><td><b style='color: red'>TOTAL:</b></td><td colspan='2' style='text-align:right; color: #2196f3;'>" + this.prCat(item).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</td></tr>"
        if (item.notasCatering) tbody.innerHTML += "<tr><td colspan='3' style='border:1px solid black; color: #2196f3; background-color: #d4ffff'><b style='color: black'>NOTAS: </b>" + item.notasCatering + "</td></tr>"
        tabla.appendChild(tbody)
        div.appendChild(tabla)
      }

      var aux = item.catering.filter(mod => [7].includes(mod.tipoModificacion))
      if (aux.length > 0){
        var tabla = v.document.createElement('table')
        tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:calc(100%/3)"
        var thead = v.document.createElement('thead')
        thead.innerHTML = '<tr><th colspan="3" style="padding: 3px;background-color: lightgray ; border:1px solid gray">ATENCIONES</th></tr>'
        thead.innerHTML += '<tr><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">ITEM</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">UDS</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRECIO</th></tr>'
        tabla.appendChild(thead)
        var tbody = v.document.createElement('tbody')
        aux.forEach(mod => {
          tbody.innerHTML += "<tr style='border: 1px solid black'><td>" + (mod.nombre) + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + (parseFloat(mod.precio || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + "€</td></tr>"
        })
        tbody.innerHTML += "<tr style='border:1px solid black;'><td><b style='color: red'>TOTAL:</b></td><td colspan='2' style='text-align:right;color: #2196f3;'>" + this.prAtenciones(item).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</td></tr>"
        if (item.notasAtenciones) tbody.innerHTML += "<tr><td colspan='3' style='border:1px solid black; color: #2196f3; background-color: #d4ffff'><b style='color: black'>NOTAS: </b>" + item.notasAtenciones + "</td></tr>"
        tabla.appendChild(tbody)
        div.appendChild(tabla)
      }


      div.innerHTML += "<div style='page-break-after: always;'></div>"
      this.anteriores.forEach(pricing => {
        var tabla = v.document.createElement('table')
        tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:100%"
        tabla.innerHTML = '<colgroup><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"></colgroup>'
        var thead = v.document.createElement('thead')
        thead.innerHTML = '<tr><th colspan="20" style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRESUPUESTO</th></tr>'
        tabla.appendChild(thead)
        var tbody = v.document.createElement('tbody')
        tbody.innerHTML = "<tr><td colspan='10' style='border:1px solid black;color: #2196f3'><b style='color:black'>FECHA PPTO: </b>" + (pricing.fecha ? new Date(pricing.fecha).toLocaleDateString('esp', { day: 'numeric', month : 'short', year: 'numeric' }).toUpperCase() : 'POR DEFINIR') + "</td><td colspan='10' style='border:1px solid black;color: #2196f3'><b style='color:black'>TIPO PPTO: </b>" + (this.tipoPpto.find(p => p.val == pricing.tipoPpto)?.title || '') + "</td></tr>"
        tabla.appendChild(tbody)
        div.appendChild(tabla)

        var tabla = v.document.createElement('table')
        tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:100%"
        tabla.innerHTML = '<colgroup><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"></colgroup>'
        var thead = v.document.createElement('thead')
        thead.innerHTML = '<tr><th colspan="20" style="padding: 3px;background-color: lightgray ; border:1px solid gray">DATOS</th></tr>'
        tabla.appendChild(thead)
        var tbody = v.document.createElement('tbody')
        tbody.innerHTML = "<tr><td colspan='8' style='border:1px solid black;color: #2196f3'><b style='color:black'>CLIENTE: </b>" + (pricing.clientes || '') + "</td><td colspan='6' style='border:1px solid black;color: #2196f3'><b style='color:black'>ESPACIO: </b>" + (this.espacios.find(e => e.idEspacio == pricing.idEspacio)?.nombreEspacio || 'POR DEFINIR') + "</td><td colspan='6' style='border:1px solid black;color: #2196f3'><b style='color:black'>FECHA: </b>" + (pricing.fechaEv ? new Date(pricing.fechaEv).toLocaleDateString('esp', { day: 'numeric', month : 'short', year: 'numeric' }).toUpperCase() : 'POR DEFINIR') + "</td></tr>"
        tbody.innerHTML += "<tr><td colspan='10' style='border:1px solid black;color: #2196f3'><b style='color:black'>PERSONAS: </b>" + (pricing.personas || '') + "</td><td colspan='10' style='border:1px solid black;color: #2196f3'><b style='color:black'>CARTA BASE: </b>" + (pricing.idCarta ? this.cartas.find(c => c.idCarta == pricing.idCarta)?.nombre + ' ' + this.espacios.find(e => e.idEspacio == this.cartas.find(c => c.idCarta == pricing.idCarta)?.idEspacio)?.nombreEspacio + this.cartas.find(c => c.idCarta == pricing.idCarta)?.year : 'POR DEFINIR') + "</td></tr>"
        if (pricing.notas) tbody.innerHTML += "<tr><td colspan='20' style='border:1px solid black; background-color: #d4ffff;color: #2196f3'><b style='color:black'>NOTAS: </b>" + pricing.notas + "</td></tr>"
        tabla.appendChild(tbody)

        var thead = v.document.createElement('thead')
        thead.innerHTML = '<tr><th colspan="20" style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRICING</th></tr>'
        var tbody = v.document.createElement('tbody')
        tbody.innerHTML += "<tr><td colspan='10' style='border:1px solid black; color: #2196f3;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b style='color: black'>Precio cliente:</b> " + parseFloat((pricing.pDef || pricing.modificaciones?.reduce((acc,val) => acc + (val.precio*val.uds),0)) || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</div></td><td colspan='10' style='border:1px solid black; color: #2196f3;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b style='color: black'>Precio pricing:</b> " + parseFloat(pricing.modificaciones?.reduce((acc,val) => acc + (val.precio*val.uds),0) || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</div></td></tr>"
        tbody.innerHTML += "<tr><td colspan='8' style='border:1px solid black; color: #2196f3;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b style='color: black'>Total descuento:</b> " + (parseFloat(pricing.pDef ? (pricing.pDef-(pricing.modificaciones?.reduce((acc,val) => acc + (val.precio*val.uds),0) || 0)) : 0) * (pricing.personas || 0)).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</div></td><td colspan='6' style='border:1px solid black; color: #2196f3;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b style='color: black'>Descuento (€):</b>" + parseFloat(pricing.pDef ? (pricing.pDef-(pricing.modificaciones?.reduce((acc,val) => acc + (val.precio*val.uds),0)) || 0) : 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</b></td><td style='border:1px solid black; color: #2196f3' colspan='6'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b style='color: black'>Descuento (%):</b>" + parseFloat((pricing.pntDif || 0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "%</b></td></tr>"
        if (pricing.notasResult) tbody.innerHTML += "<tr><td colspan='20' style='border:1px solid black; color: #2196f3; background-color: #d4ffff'><b style='color: black'>NOTAS: </b>" + pricing.notasResult + "</td></tr>"
        tabla.appendChild(thead)
        tabla.appendChild(tbody)
        div.appendChild(tabla)

        var aux = pricing.modificaciones.filter(mod => [1,2,3].includes(mod.tipoModificacion))
        pricing.modificacionesCustom.map(m => {m.editable = true; return m}).forEach(element => {
          aux.splice(element.orden,0,element)
        });
        if (aux.length > 0){
          var tabla = v.document.createElement('table')
          tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:calc(100%/3)"
          var thead = v.document.createElement('thead')
          thead.innerHTML = '<tr><th colspan="3" style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRICING</th></tr>'
          thead.innerHTML += '<tr><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">ITEM</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">UDS</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRECIO</th></tr>'
          tabla.appendChild(thead)
          var tbody = v.document.createElement('tbody')
          if (pricing.idCarta) tbody.innerHTML += "<tr style='border: 1px solid black'><td>" + ((this.cartas.find(c => c.idCarta == pricing.idCarta)?.nombre || '') + " " + (this.espacios.find(e => e.idEspacio == this.cartas.find(c => c.idCarta == pricing.idCarta)?.idEspacio)?.nombreEspacio || '') + " " + (this.cartas.find(c => c.idCarta == pricing.idCarta)?.year || '')) + "</td><td style='text-align:center'>1</td><td style='text-align:right'>" + parseFloat(this.cartas.find(c => c.idCarta == pricing.idCarta)?.precio || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "€</td></tr>"
          aux.forEach(mod => {
            if (mod.editable) tbody.innerHTML += "<tr style='border: 1px solid black'><td><b style='color: red'>" + ["ADD ", "QUIT ", "SUST "][mod.tipoModificacion-1] + "</b>" + (mod.nombre || '') + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + (parseFloat(mod.precio || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + "€</td></tr>"
            else tbody.innerHTML += "<tr style='border: 1px solid black'><td><b style='color: red'>" + ["ADD ", "QUIT ", "SUST "][mod.tipoModificacion-1] + "</b>" + (this.modificaciones.find(m => m.idItem == mod.idItem)?.nombre) + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + ([parseFloat(mod.add || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, "."), parseFloat(mod.remove || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, "."), parseFloat(mod.replace || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")][mod.tipoModificacion-1]) + "€</td></tr>"
          })
          if (pricing.notasModificaciones) tbody.innerHTML += "<tr><td colspan='3' style='border:1px solid black; color: #2196f3; background-color: #d4ffff'><b style='color: black'>NOTAS: </b>" + pricing.notasModificaciones + "</td></tr>"
          tabla.appendChild(tbody)
          div.appendChild(tabla)
        }

        var aux = pricing.catering.filter(mod => [4,5,6].includes(mod.tipoModificacion))
        if (aux.length > 0){
          var tabla = v.document.createElement('table')
          tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:calc(100%/3)"
          var thead = v.document.createElement('thead')
          thead.innerHTML = '<tr><th colspan="3" style="padding: 3px;background-color: lightgray ; border:1px solid gray">CATERING</th></tr>'
          thead.innerHTML += '<tr><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">ITEM</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">UDS</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRECIO</th></tr>'
          tabla.appendChild(thead)
          var tbody = v.document.createElement('tbody')
          aux.forEach(mod => {
            tbody.innerHTML += "<tr style='border: 1px solid black'><td><b style='color: red'>" + ["ADD ", "QUIT ", "SUST "][mod.tipoModificacion-4] + "</b>" + (mod.nombre) + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + parseFloat(mod.precio || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "€</td></tr>"
          })
          tbody.innerHTML += "<tr style='border:1px solid black;'><td><b style='color: red'>TOTAL:</b></td><td colspan='2' style='text-align:right; color: #2196f3;'>" + parseFloat(pricing.catering.filter(p => [4,5,6].includes(p.tipoModificacion)).reduce((acc,val) => acc + (val.precio * val.uds), 0)).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</td></tr>"
          if (pricing.notasCatering) tbody.innerHTML += "<tr><td colspan='3' style='border:1px solid black; color: #2196f3; background-color: #d4ffff'><b style='color: black'>NOTAS: </b>" + pricing.notasCatering + "</td></tr>"
          tabla.appendChild(tbody)
          div.appendChild(tabla)
        }

        var aux = pricing.catering.filter(mod => [7].includes(mod.tipoModificacion))
        if (aux.length > 0){
          var tabla = v.document.createElement('table')
          tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:calc(100%/3)"
          var thead = v.document.createElement('thead')
          thead.innerHTML = '<tr><th colspan="3" style="padding: 3px;background-color: lightgray ; border:1px solid gray">ATENCIONES</th></tr>'
          thead.innerHTML += '<tr><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">ITEM</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">UDS</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRECIO</th></tr>'
          tabla.appendChild(thead)
          var tbody = v.document.createElement('tbody')
          aux.forEach(mod => {
            tbody.innerHTML += "<tr style='border: 1px solid black'><td>" + (mod.nombre) + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + (parseFloat(mod.precio || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + "€</td></tr>"
          })
          tbody.innerHTML += "<tr style='border:1px solid black;'><td><b style='color: red'>TOTAL:</b></td><td colspan='2' style='text-align:right; color: #2196f3;'>" + parseFloat(pricing.catering.filter(p => [7].includes(p.tipoModificacion)).reduce((acc,val) => acc + (val.precio * val.uds), 0)).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</td></tr>"
          if (pricing.notasAtenciones) tbody.innerHTML += "<tr><td colspan='3' style='border:1px solid black; color: #2196f3; background-color: #d4ffff'><b style='color: black'>NOTAS: </b>" + pricing.notasAtenciones + "</td></tr>"
          tabla.appendChild(tbody)
          div.appendChild(tabla)
        }
      div.innerHTML += "<div style='page-break-after: always;'></div>"
      });
      
      wrapper.appendChild(div)
      v.document.write(wrapper.outerHTML)
      v.print()
      v.close()
      this.$set(this,'anteriores',[])
    },
    exportarExcel(item) {
      this.$set(this,'anteriores',this.pricings.filter(p => p.idPricing == item.idPricing && p.revision != item.revision))
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('PRICING');
      var rows = [
        [null,"CLIENTES",(item.clientes || '')],
        [null,"FECHA EVENTO",item.fechaEv ? new Date(item.fechaEv).toLocaleDateString('esp', { month : 'short', day: 'numeric' }).replace(" ", "-") : 'POR DEFINIR'],
        [null,"ESPACIO",(this.espacios.find( e => e.idEspacio == item.idEspacio)?.nombreEspacio || 'POR DEFINIR')],
        [null,"PERSONAS",(item.personas || 0)],
        [null,"TIPO PPTO",(this.tipoPpto.find(p => p.val == item.tipoPpto)?.title || 'POR DEFINIR')],
        [null,"FECHA PPTO",new Date((item.fecha || '')).toLocaleDateString('esp', { month : 'short', day: 'numeric' }).replace(" ", "-")],
        [null,"CARTA USADA", ((this.cartas.find(c => c.idCarta == item.idCarta)?.nombre || '') + " " + (this.espacios.find(e => e.idEspacio == this.cartas.find(c => c.idCarta == item.idCarta)?.idEspacio)?.nombreEspacio || '') + " " + (this.cartas.find(c => c.idCarta == item.idCarta)?.year || ''))],
        [null,"PRECIO BASE", (this.cartas.find(c => c.idCarta == item.idCarta )?.precio || 0)],
        [null,(item.notas || '')],
      ];
      sheet.insertRows(2, rows)
      sheet.mergeCells(2,3,2,4);
      sheet.mergeCells(3,3,3,4);
      sheet.mergeCells(4,3,4,4);
      sheet.mergeCells(5,3,5,4);
      sheet.mergeCells(6,3,6,4);
      sheet.mergeCells(7,3,7,4);
      sheet.mergeCells(8,3,8,4);
      sheet.mergeCells(9,3,9,4);
      sheet.mergeCells(10,2,10,4);

      var shiftedRows = rows.length+2
      sheet.insertRow(shiftedRows, [null,null,null,null])
      shiftedRows++

      sheet.getColumn(2).alignment = { vertical: 'middle', horizontal: 'left', wrapText: false };
      sheet.getColumn(2).width = 20
      sheet.getColumn(5).width = 20
      sheet.getRow(10).height = 100
      sheet.getColumn(4).alignment = { vertical: 'middle', horizontal: 'right', wrapText: false };
      sheet.getCell("C9").numFmt = "#,##0.00 €"

      sheet.getCell("B2").border = {
        top: {style:'thick'},
        left: {style:'thick'},
      };
      sheet.getCell("B3").border = {
        left: {style:'thick'},
      };
      sheet.getCell("B4").border = {
        left: {style:'thick'},
      };
      sheet.getCell("B5").border = {
        left: {style:'thick'},
      };
      sheet.getCell("B6").border = {
        left: {style:'thick'},
      };
      sheet.getCell("B7").border = {
        left: {style:'thick'},
      };
      sheet.getCell("B8").border = {
        left: {style:'thick'},
      };
      sheet.getCell("B9").border = {
        left: {style:'thick'},
        bottom: {style:'thick'},
      };
      sheet.getCell("B10").border = {
        bottom: {style:'thick'},
        left: {style:'thick'},
        right: {style:'thick'},
      };
      sheet.getCell("B10").font = {
        color: { argb: 'FF2196F3' },
      }
      sheet.getCell("B10").alignment = {
        horizontal : 'left',
        vertical : 'top',
        wrapText : true
      }

      sheet.getCell("C2").border = {
        top: {style:'thick'},
        right: {style:'thick'},
      };
      sheet.getCell("C3").border = {
        right: {style:'thick'},
      };
      sheet.getCell("C4").border = {
        right: {style:'thick'},
      };
      sheet.getCell("C5").border = {
        right: {style:'thick'},
      };
      sheet.getCell("C6").border = {
        right: {style:'thick'},
      };
      sheet.getCell("C7").border = {
        right: {style:'thick'},
      };
      sheet.getCell("C8").border = {
        right: {style:'thick'},
      };
      sheet.getCell("C9").border = {
        right: {style:'thick'},
        bottom: {style:'thick'},
      };

      var aux = item.catering.filter(mod => [4,5,6].includes(mod.tipoModificacion))
      if (aux.length > 0 ||item.notasCatering){
        sheet.insertRow(shiftedRows, [null,"CATERING & EXTRAS",null])
        sheet.getRow(shiftedRows).getCell(2).alignment = { horizontal: 'center'}
        sheet.getRow(shiftedRows).getCell(2).border = {
          top: {style:'thick'},
          left: {style:'thick'},
          bottom: {style:'thick'},
          right: {style:'thick'}
        };
        sheet.mergeCells(shiftedRows,2,shiftedRows,4);
        shiftedRows++
        sheet.insertRow(shiftedRows, [null,"ITEM","UDS","PRECIO"])
        sheet.getRow(shiftedRows).getCell(2).border = {
          top: {style:'thin'},
          left: {style:'thick'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        sheet.getRow(shiftedRows).getCell(3).border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        sheet.getRow(shiftedRows).getCell(4).alignment = { horizontal : 'left' }
        sheet.getRow(shiftedRows).getCell(4).border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thick'}
        };
        shiftedRows++

        aux.forEach(mod => {
          sheet.insertRow(shiftedRows,[null,["ADD ", "QUIT ", "SUST "][mod.tipoModificacion-4] + (mod.nombre || ''),(mod.uds || 1),(parseFloat(mod.precio || 0))])
          sheet.getRow(shiftedRows).getCell(4).numFmt = "#,##0.00 €"
          sheet.getRow(shiftedRows).getCell(2).border = {
            top: {style:'thin'},
            left: {style:'thick'},
            bottom: {style:'thin'},
            right: {style:'thin'}
          };
          sheet.getRow(shiftedRows).getCell(3).border = {
            bottom: {style:'thin'},
          };
          sheet.getRow(shiftedRows).getCell(4).border = {
            top: {style:'thin'},
            left: {style:'thin'},
            bottom: {style:'thin'},
            right: {style:'thick'}
          };
          shiftedRows++
        })
        if ( item.notasCatering ){
          sheet.insertRow(shiftedRows,[null,(item.notasCatering || '')])
          sheet.mergeCells(shiftedRows,2,shiftedRows,4)
          sheet.getRow(shiftedRows).getCell(2).border = {
            top: {style:'thick'},
            left: {style:'thick'},
            bottom: {style:'thick'},
            right: {style:'thick'},
          };
          sheet.getRow(shiftedRows).getCell(2).font = {
            color: { argb: 'FF2196F3' },
          }
          sheet.getRow(shiftedRows).height = 100
          shiftedRows++
        }
        sheet.insertRow(shiftedRows)
        shiftedRows++
      }

      var aux = item.catering.filter(mod => [7].includes(mod.tipoModificacion))
      if (aux.length > 0 ||item.notasAtenciones){
        sheet.insertRow(shiftedRows, [null,"ATENCIONES",null])
        sheet.getRow(shiftedRows).getCell(2).alignment = { horizontal: 'center'}
        sheet.getRow(shiftedRows).getCell(2).border = {
          top: {style:'thick'},
          left: {style:'thick'},
          bottom: {style:'thick'},
          right: {style:'thick'}
        };
        sheet.mergeCells(shiftedRows,2,shiftedRows,4);
        shiftedRows++
        sheet.insertRow(shiftedRows, [null,"ITEM","UDS","PRECIO"])
        sheet.getRow(shiftedRows).getCell(2).border = {
          top: {style:'thin'},
          left: {style:'thick'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        sheet.getRow(shiftedRows).getCell(3).border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        sheet.getRow(shiftedRows).getCell(4).alignment = { horizontal : 'left' }
        sheet.getRow(shiftedRows).getCell(4).border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thick'}
        };
        shiftedRows++

        aux.forEach(mod => {
          sheet.insertRow(shiftedRows,[null,(mod.nombre || ''),(mod.uds || 1),(parseFloat(mod.precio || 0))])
          sheet.getRow(shiftedRows).getCell(4).numFmt = "#,##0.00 €"
          sheet.getRow(shiftedRows).getCell(2).border = {
            top: {style:'thin'},
            left: {style:'thick'},
            bottom: {style:'thin'},
            right: {style:'thin'}
          };
          sheet.getRow(shiftedRows).getCell(3).border = {
            bottom: {style:'thin'},
          };
          sheet.getRow(shiftedRows).getCell(4).border = {
            top: {style:'thin'},
            left: {style:'thin'},
            bottom: {style:'thin'},
            right: {style:'thick'}
          };
          shiftedRows++
        })
        if(item.notasAtenciones){
          sheet.insertRow(shiftedRows,[null,(item.notasAtenciones || '')])
          sheet.mergeCells(shiftedRows,2,shiftedRows,4)
          sheet.getRow(shiftedRows).getCell(2).border = {
            top: {style:'thick'},
            left: {style:'thick'},
            bottom: {style:'thick'},
            right: {style:'thick'},
          };
          sheet.getRow(shiftedRows).getCell(2).font = {
            color: { argb: 'FF2196F3' },
          }
          sheet.getRow(shiftedRows).height = 100
          shiftedRows++
        }
        sheet.insertRow(shiftedRows)
        shiftedRows++
      }

      var aux = item.modificaciones.filter(mod => [1,2,3].includes(mod.tipoModificacion))
      if (aux.length > 0 ||item.notasModificaciones){
        sheet.insertRow(shiftedRows, [null,"DETALLES PRICING"])
        sheet.getRow(shiftedRows).getCell(2).alignment = { horizontal: 'center'}
        sheet.getRow(shiftedRows).getCell(2).border = {
          top: {style:'thick'},
          left: {style:'thick'},
          bottom: {style:'thick'},
          right: {style:'thick'}
        };
        sheet.mergeCells(shiftedRows,2,shiftedRows,4);
        shiftedRows++
        sheet.insertRow(shiftedRows, [null,"ITEM","UDS","PRECIO"])
        sheet.getRow(shiftedRows).getCell(2).border = {
          top: {style:'thin'},
          left: {style:'thick'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        sheet.getRow(shiftedRows).getCell(3).border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
        sheet.getRow(shiftedRows).getCell(4).alignment = { horizontal : 'left' }
        sheet.getRow(shiftedRows).getCell(4).border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thick'}
        };
        shiftedRows++

        aux.forEach(mod => {
          sheet.insertRow(shiftedRows,[null,["ADD ", "QUIT ", "SUST "][mod.tipoModificacion-1] + (this.modificaciones.find(m => m.idItem == mod.idItem)?.nombre || ''),(mod.uds || 1),([parseFloat(this.modificaciones.find(m => m.idItem == mod.idItem)?.add || 0), parseFloat(this.modificaciones.find(m => m.idItem == mod.idItem)?.remove || 0), parseFloat(this.modificaciones.find(m => m.idItem == mod.idItem)?.replace || 0)][mod.tipoModificacion-1])])
          sheet.getRow(shiftedRows).getCell(4).numFmt = "#,##0.00 €"
          sheet.getRow(shiftedRows).getCell(2).border = {
            top: {style:'thin'},
            left: {style:'thick'},
            bottom: {style:'thin'},
            right: {style:'thin'}
          };
          sheet.getRow(shiftedRows).getCell(3).border = {
            bottom: {style:'thin'},
          };
          sheet.getRow(shiftedRows).getCell(4).border = {
            top: {style:'thin'},
            left: {style:'thin'},
            bottom: {style:'thin'},
            right: {style:'thick'}
          };
          shiftedRows++
        })
        if (item.notasModificaciones){
          sheet.insertRow(shiftedRows,[null,(item.notasModificaciones || '')])
          sheet.mergeCells(shiftedRows,2,shiftedRows,4)
          sheet.getRow(shiftedRows).getCell(2).border = {
            top: {style:'thick'},
            left: {style:'thick'},
            bottom: {style:'thick'},
            right: {style:'thick'},
          };
          sheet.getRow(shiftedRows).getCell(2).font = {
            color: { argb: 'FF2196F3' },
          }
          sheet.getRow(shiftedRows).height = 100
          shiftedRows++
        }
        sheet.insertRow(shiftedRows)
        shiftedRows++
      }

      sheet.insertRow(shiftedRows,[null,"TARIFAS"])
      sheet.mergeCells(shiftedRows, 2, shiftedRows, 4)
      sheet.getRow(shiftedRows).getCell(2).alignment = {
        horizontal : 'center'
      }
      sheet.getRow(shiftedRows).getCell(2).border = {
        left: {style:'thick'},
        right: {style:'thick'},
        top: {style:'thick'},
        bottom: {style:'thick'},
      };
      shiftedRows++

      const old = shiftedRows
      sheet.insertRow(shiftedRows,[null,"PRECIO PRICING", (this.prTotal(item) || 0.00)])
      sheet.getRow(old).getCell(2).border = {
        left: {style:'thick'},
        top: {style:'thick'},
        bottom: {style:'thick'},
      };
      sheet.getRow(old).getCell(3).border = {
        right: {style:'thick'},
        top: {style:'thick'},
        bottom: {style:'thick'},
      };
      
      sheet.mergeCells(old, 3, old, 4)
      sheet.getRow(shiftedRows).getCell(3).numFmt = "#,##0.00 €"
      sheet.getRow(shiftedRows).getCell(3).alignment = {
        horizontal : 'right'
      }
      shiftedRows++

      sheet.insertRow(shiftedRows,[null,"PRECIO ENVIADO", (item.pDef || 0.00)])
      sheet.getRow(old+1).getCell(2).border = {
        left: {style:'thick'},
        top: {style:'thick'},
        bottom: {style:'thick'},
      };
      sheet.getRow(old+1).getCell(3).border = {
        right: {style:'thick'},
        top: {style:'thick'},
        bottom: {style:'thick'},
      };

      sheet.mergeCells(old+1, 3, old+1, 4)
      sheet.getRow(shiftedRows).getCell(3).numFmt = "#,##0.00 €"
      sheet.getRow(shiftedRows).getCell(3).alignment = {
        horizontal : 'right'
      }
      shiftedRows++

      sheet.insertRow(shiftedRows,[null,"DIFERENCIA (€)", (item.pDef ? (item.pDef-this.prTotal(item)) : 0.00)])
      sheet.getRow(old+2).getCell(2).border = {
        left: {style:'thin'},
        top: {style:'thin'},
        bottom: {style:'thin'},
      };
      sheet.getRow(old+2).getCell(3).border = {
        right: {style:'thin'},
        top: {style:'thin'},
        bottom: {style:'thin'},
      };

      sheet.mergeCells(old+2, 3, old+2, 4)
      sheet.getRow(shiftedRows).getCell(3).numFmt = "#,##0.00 €"
      sheet.getRow(shiftedRows).getCell(3).alignment = {
        horizontal : 'right'
      }
      shiftedRows++

      sheet.insertRow(shiftedRows,[null,"DIFERENCIA (%)", (item.pntDif || 0.00)])
      sheet.getRow(old+3).getCell(2).border = {
        left: {style:'thin'},
        top: {style:'thin'},
        bottom: {style:'thin'},
      };
      sheet.getRow(old+3).getCell(3).border = {
        right: {style:'thin'},
        top: {style:'thin'},
        bottom: {style:'thin'},
      };

      sheet.mergeCells(old+3, 3, old+3, 4)
      sheet.getRow(shiftedRows).getCell(3).numFmt = "#,##0.00 %"
      sheet.getRow(shiftedRows).getCell(3).alignment = {
        horizontal : 'right'
      }
      shiftedRows++

      sheet.insertRow(shiftedRows,[null,"TOTAL DESC/INC", (item.pDef ? (item.pDef-this.prTotal(item)) : 0) * (item.personas || 0)])
      sheet.getRow(old+4).getCell(2).border = {
        left: {style:'thick'},
        top: {style:'thick'},
        bottom: {style:'thick'},
      };
      sheet.getRow(old+4).getCell(3).border = {
        right: {style:'thick'},
        top: {style:'thick'},
        bottom: {style:'thick'},
      };
      
      sheet.mergeCells(old+4, 3, old+4, 4)
      sheet.getRow(shiftedRows).getCell(3).numFmt = "#,##0.00 €"
      sheet.getRow(shiftedRows).getCell(3).alignment = {
        horizontal : 'right'
      }
      shiftedRows++
      
      if ( item.notasResult ){
        sheet.insertRow(shiftedRows,[null,(item.notasResult || '')])
        sheet.mergeCells(shiftedRows,2,shiftedRows,4)
        sheet.getRow(shiftedRows).getCell(2).border = {
          top: {style:'thick'},
          left: {style:'thick'},
          bottom: {style:'thick'},
          right: {style:'thick'},
        };
        sheet.getRow(shiftedRows).getCell(2).font = {
          color: { argb: 'FF2196F3' },
        }
        sheet.getRow(shiftedRows).height = 100
        shiftedRows++
      }

      workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${(item.fechaEv ? new Date(new Date(item.fechaEv).getTime() + (new Date().getTimezoneOffset()*-1)*60000).toISOString().split('T')[0] : 'POR DEFINIR') + " " + item.clientes + " " + (this.espacios.find(e => e.idEspacio == item.idEspacio)?.siglas || 'PTE') + " " + (this.tipoPpto.find(e => e.val == item.tipoPpto)?.siglas || '?') + " (" + (item.fechaEv ? new Date(new Date(item.fechaEv).getTime() + (new Date().getTimezoneOffset()*-1)*60000).toISOString().split('T')[0] : 'Missing No.')}).xlsx`))
      .catch(err => console.log('Error writing excel export', err))
      this.$set(this,'anteriores',[])
    },
    prTotal(item){
      console.log(item);
      var tot = (this.cartas.find(c => c.idCarta == item.idCarta)?.precio || 0)
      if (!item.modificaciones) item.modificaciones = []
      item.modificaciones.forEach(mod => {
        var add = 0
        if (mod.tipoModificacion==1){
          add = (this.modificaciones.find(m => m.idItem == mod.idItem)?.add || 0)
        }
        else if (mod.tipoModificacion==2){
          add = (this.modificaciones.find(m => m.idItem == mod.idItem)?.remove || 0)
        }
        else if (mod.tipoModificacion==3){
          add = (this.modificaciones.find(m => m.idItem == mod.idItem)?.replace || 0)
        }
        tot += (mod.uds * add)
      })
      if (!item.modificacionesCustom) item.modificacionesCustom = []
      item.modificacionesCustom.forEach(mod => {
        var add = 0
        if (mod.tipoModificacion==1){
          add = mod.precio
        }
        else if (mod.tipoModificacion==2){
          add = Math.abs(mod.precio)*-1
        }
        else if (mod.tipoModificacion==3){
          add = mod.precio
        }
        tot += (mod.uds * add)
      })
      return parseFloat(tot || 0)
    },
    prCat(item){
      var tot = 0
      if (!item.catering) item.catering = []
      item.catering.filter(m => [4,5,6].includes(m.tipoModificacion)).forEach(mod => {
        var add = mod.precio
        tot += (mod.uds * add)
      })
      return parseFloat(tot || 0)
    },
    prAtenciones(item){
      var tot = 0
      if (!item.catering) item.catering = []
      item.catering.filter(m => [7].includes(m.tipoModificacion)).forEach(mod => {
        var add = mod.precio
        tot += (mod.uds * add)
      })
      return parseFloat(tot || 0)
    },
    getRev(item){
      this.$set(this,'revision',item)
      this.$set(this,'anteriores',this.pricings.filter(p => p.idPricing == item.idPricing && p.revision != item.revision))
      item.fecha = new Date().getFullYear() + '-' + ('0'+(new Date().getMonth()+1)).slice(-2) + '-' + ('0'+new Date().getDate()).slice(-2)
      item.pDef = 0
      this.$set(this,'pricing',item)
      this.$forceUpdate()
    },
    reload(){
      this.revision = null
      this.$emit('reload')
    },
    parseDate,
    async deletePricing({ idPricing, clientes, revision }) {
			const confirmation = await this.$refs.confirm.open(
				`Pricing: ${clientes} | Revisión: ${revision}`,
        `ATENCIÓN: ESTA ACCIÓN ES IRREVERSIBLE SIN UNA COPIA DE SEGURIDAD. ¿Seguro que quieres eliminar este pricing?`,
        { color: "error" }
      );
      if (!confirmation) return;

      const { data } = await axios({
        method: "DELETE",
        url: "/pricings/" + idPricing + "/" + revision,
      });
      this.$emit("reload")
    }
  },
};
</script>
<style>
.active{
  max-height: 10000px;
}
.activent{
  max-height: 0px !important;
}
</style>